import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import globalReducer from "./reducers/globalReducer";
import {composeWithDevTools} from "@redux-devtools/extension";
import allNFTReducer from "./reducers/allNFTReducer";
import myNFTReducer from "./reducers/myNFTReducer";


const rootReducer = combineReducers({
  global: globalReducer,
  all: allNFTReducer,
  my: myNFTReducer,
})

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

export default store