import React from 'react';
import styles from './styles/guide.module.css'
import {NavLink, useNavigate} from "react-router-dom";
import logo from "../common/logo.png";
import geometry_small from '../common/geometry_guide_small.svg'
import blue_cloud from '../common/blue_cloud_guide.svg'
import yellow_cloud from '../common/yellow_cloud_guide.svg'
import white_cloud from '../common/white_cloud_guide.svg'
import green_cloud from '../common/green_cloud_guide.svg'
import purple_cloud from '../common/purple_cloud_guide.svg'
import orange_cloud from '../common/orange_cloud_guide.svg'
import cloud_mid from '../common/cloud_mid.svg'
import cloud_big from '../common/cloud_big.svg'
import arrow_right from '../common/arrow_right.svg'
import second_step from '../common/second_step.png'
import third_step from '../common/third_step.png'
import fourth_step from '../common/fourth_step.png'
import fifth_step from '../common/fifth_step.png'
import worm from '../common/guide_worm.svg'
import metamask_first_step from '../common/metamask_first_step.png'
import metamask_second_step from '../common/metamask_second_step.png'
import metamask_third_step from '../common/metamask_third_step.png'
import classNames from "classnames";

const Guide = ({scrollToTop}) => {

  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <NavLink to='/' className={styles.logo}>
          <img src={logo} alt="Logo" width={40} height={40}/>
          <span className={styles.title}>the worms.<span className={styles.title_semi}>xyz</span></span>
        </NavLink>
        <button className={styles.button_top} onClick={() => navigate(-1)}>
          <div className={styles.img_wrapper}>
            <img src={arrow_right} alt="to top" width={8} height={12} className={styles.arrow_back}/>
          </div>
          <span className={styles.button_top_text}>Back</span>
        </button>
      </div>
      <div className={styles.subHeader}>
        <div className={styles.subHeader_wrapper}>
          <h1 className={styles.bigTitle}><span className={styles.bigTitle_green}>How to create</span> your wallet</h1>
          <img src={geometry_small} alt="background" className={styles.geometry_small}/>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.first_step}>
          <img src={blue_cloud} alt="Step 1"/>
          <div className={styles.first_step_info}>
            <span className={styles.first_step_info_text}>Download<br/><span className={styles.semibold}>the MetaMask extension</span><br/>to the browser</span>
            <img src={arrow_right} alt="Next step" width={13} height={19}/>
            <span className={styles.first_step_info_text}>Select <br/><span
              className={styles.semibold}>"Create wallet"</span></span>
            <img src={arrow_right} alt="Next step" width={13} height={19}/>
            <span className={styles.first_step_info_text}>MetaMask can only<br/>be downloaded<br/>in Chrome and Firefox</span>
          </div>
        </div>
        <div className={styles.second_step}>
          <div className={styles.second_step_text}>
            <img src={yellow_cloud} alt="Step 2"/>
            <p className={styles.yellow_cloud_info}>Accept the terms of use</p>
          </div>
          <div className={styles.image_wrapper}>
            <img src={second_step} alt="Second step" width={528} height={485}/>
          </div>
        </div>
        <div className={styles.third_step}>
          <div className={styles.third_step_text}>
            <img src={white_cloud} alt="Step 3"/>
            <p className={styles.yellow_cloud_info}>
              Open the original phrase
              <span className={styles.white_cloud_info}>
                Be extremely careful and save all phrases in a<br/>
                secure storage or storage medium (preferably<br/>
                not on a device connected to the Internet).
              </span>
              Enter each word in the correct order
            </p>
          </div>
          <div className={styles.image_wrapper}>
            <img src={third_step} alt="Second step" width={528} height={437}/>
          </div>
        </div>
        <div className={styles.fourth_step}>
          <div className={styles.third_step_text}>
            <img src={green_cloud} alt="Step 3"/>
          </div>
          <div className={styles.image_wrapper}>
            <img src={cloud_big} alt="background" className={styles.fourth_cloud}/>
            <img src={fourth_step} alt="Second step" width={528} height={397}/>
          </div>
        </div>
      </div>
      <div className={styles.second_wrapper}>
        <div className={styles.wrapper}>
          <div className={styles.metamask_first_step}>
            <div className={styles.image_wrapper}>
              <img src={metamask_first_step} alt="Second step" width={528} height={398}/>
            </div>
            <div className={styles.text_wrapper}>
              <span className={classNames(styles.metamask_text, styles.metamask_text_column)}>
                <span className={styles.semibold}>You have a new MetaMask wallet!</span>
                <span>Now switch to the Binance Smart Chain (Binance) network in the list of networks:</span>
              </span>
              <span className={styles.metamask_subtext}>Select "Settings" from the drop-down menu</span>
            </div>
          </div>
          <div className={styles.metamask_first_step}>
            <div className={styles.image_wrapper}>
              <img src={metamask_second_step} alt="Second step" width={528} height={398}/>
            </div>
            <div className={styles.text_wrapper}>
              <span className={styles.metamask_text}>Select
                <span className={styles.semibold}>&nbsp;"Networks"&nbsp;</span>
                <span className={styles.orange_text}>1</span>
              </span>
              <span className={styles.metamask_text}>and click
                <span className={styles.semibold}>&nbsp;"Add Network"&nbsp;</span>
                <span className={styles.yellow_text}>2</span>
              </span>
              <span className={styles.metamask_text}>in the upper right corner.</span>
            </div>
          </div>
          <div className={styles.metamask_first_step}>
            <div className={styles.image_wrapper}>
              <img src={metamask_third_step} alt="Third step" width={528} height={398}/>
            </div>
            <div className={styles.text_wrapper}>
              <span className={classNames(styles.metamask_text, styles.metamask_text_column)}>
                <span className={styles.semibold}>Enter the following information</span>
                about Polygon network:
              </span>
              <div className={styles.network_info}>
                <div className={styles.info_row}>
                  <span className={styles.info_title}>Network Name:</span>
                  <span className={styles.info_text}>Polygon Mainnet</span>
                </div>
                <div className={styles.info_row}>
                  <span className={styles.info_title}>New RPC URL:</span>
                  <span className={styles.info_text}>https://polygon-rpc.com</span>
                </div>
                <div className={styles.info_row}>
                  <span className={styles.info_title}>ChainID:</span>
                  <span className={styles.info_text}>137</span>
                </div>
                <div className={styles.info_row}>
                  <span className={styles.info_title}>Currency Symbol:</span>
                  <span className={styles.info_text}>MATIC</span>
                </div>
                <div className={styles.info_row}>
                  <span className={styles.info_title}>Block Explorer URL:</span>
                  <span className={styles.info_text}>https://polygonscan.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.third_wrapper}>
        <div className={styles.second_step}>
          <div className={styles.fifth_step_info}>
            <img src={purple_cloud} alt="Step 5" width={166} height={107}/>
            <span className={styles.fifth_step_text}>
              Copy your wallet address <br/> by pressing Account 1
            </span>
            <span className={styles.fifth_step_text}>
              Go to the website
              <br/>
              <span className={styles.semibold}>https://theworms.xyz</span>
            </span>
            <span className={styles.fifth_step_text}>
              In the section <span className={styles.semibold}>“Get tokens”</span>
              <br/>
              insert your wallet
            </span>
          </div>
          <div className={styles.image_wrapper}>
            <img src={fifth_step} alt="Fifth step" width={528} height={397}/>
          </div>
        </div>
        <div className={styles.mint_info}>
          <h2 className={styles.small_title}><span className={styles.bigTitle_green}>How to mint</span> worm</h2>
          <div className={styles.mint_step}>
            <h3 className={styles.mint_first_step_title}>Step 1</h3>
            <ul className={styles.mint_list}>
              <li className={styles.mint_item}>Click the Connect Wallet button at https://theworms.xyz</li>
              <li className={styles.mint_item}>You will be automatically connected!</li>
            </ul>
          </div>
          <div className={styles.mint_step}>
            <h3 className={styles.mint_second_step_title}>Step 2</h3>
            <ul className={styles.mint_list}>
              <li className={styles.mint_item}>Then you will see the number of Worms available</li>
              <li className={styles.mint_item}>Click “Mint”</li>
            </ul>
          </div>
          <div className={styles.mint_step}>
            <h3 className={styles.mint_third_step_title}>Step 3</h3>
            <span className={styles.mint_item}>Go through allowance* and confirm the transaction in the MetaMask dialogue window</span>
          </div>
          <div className={styles.mint_step}>
            <h3 className={styles.mint_fourth_step_title}>Step 4</h3>
            <span className={styles.mint_item}>Confirm the transaction in the dialogue window.</span>
            <p className={styles.mint_text}>
              Important: purchased Worms can be found in the inventory of your profile. The Worm will not appear
              immediately — it takes time for the transaction to proceed through a number of confirmation steps (mined
              in blockchain and processed on our backend).
            </p>
          </div>
        </div>
      </div>
      <div className={styles.fourth_wrapper}>
        <div className={styles.wrapper}>
          <h2 className={styles.small_title}><span className={styles.bigTitle_green}>How to</span> upgrade worm</h2>
          <div className={styles.steps}>
            <div className={styles.upgrade_first_step}>
              <img src={blue_cloud} alt="Step 1" className={styles.upgrade_blue_cloud}/>
              <ul className={styles.mint_list}>
                <li className={styles.mint_item}>Click the Connect Wallet button at<br/>https://theworms.xyz</li>
                <li className={styles.mint_item}>You will be automatically connected!</li>
              </ul>
            </div>
            <div className={styles.upgrade_second_step}>
              <img src={yellow_cloud} alt="Step 2" className={styles.upgrade_blue_cloud}/>
              <ul className={styles.mint_list}>
                <li className={classNames(styles.mint_item, styles.mint_item_margin)}>After you have minted a worm, you
                  will receive one apple every 24 <br/>
                  hours Each worm generates apples separately and on its own timer <br/>
                  (which starts counting at the moment of mint)
                </li>
                <li className={classNames(styles.mint_item, styles.mint_item_margin)}>Press the button “Claim apples” to
                  collect them
                </li>
                <li className={classNames(styles.mint_item, styles.mint_item_margin)}>Confirm the transaction in the
                  dialogue window
                </li>
              </ul>
            </div>
            <div className={styles.upgrade_third_step}>
              <img src={white_cloud} alt="Step 3" className={styles.upgrade_blue_cloud}/>
              <ul className={styles.mint_list}>
                <li className={styles.mint_item}>Go to the “My Worms” tab</li>
              </ul>
            </div>
            <div className={styles.upgrade_fourth_step}>
              <img src={orange_cloud} alt="Step 4" className={styles.upgrade_blue_cloud}/>
              <ul className={styles.mint_list}>
                <li className={classNames(styles.mint_item, styles.mint_item_margin)}>
                  Your worms have an upgrade button. Press it to perform a transaction <br/>
                  and increase the level of the worm in exchange for apples (important! <br/>
                  you must have MATIC to perform the transaction)
                </li>
                <li className={classNames(styles.mint_item, styles.mint_item_margin)}>
                  The worm requires 14 apples to level up from 0 to 1, <br/>
                  and 28 to level up from 1 to 2
                </li>
              </ul>
            </div>
          </div>
        </div>
        <img src={worm} alt="Worm" width={505} height={700} className={styles.worm}/>
      </div>
      <footer className={styles.footer}>
        <NavLink to='/' className={styles.logo}>
          <img src={logo} alt="Logo" width={40} height={40}/>
          <span className={styles.title}>the worms.<span className={styles.title_semi}>xyz</span></span>
        </NavLink>
        <button className={styles.button_top} onClick={scrollToTop}>
          <div className={styles.img_wrapper}>
            <img src={arrow_right} alt="to top" width={8} height={12} className={styles.arrow_top}/>
          </div>
          <span className={styles.button_top_text}>Up</span>
        </button>
      </footer>
      <img src={cloud_mid} alt="background" className={styles.cloud_mid}/>
      <img src={cloud_big} alt="background" className={styles.cloud_big}/>
    </div>
  );
};

export default Guide;
