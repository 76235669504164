import React, {useState} from 'react';
import styles from './styles/card.module.css'
import classNames from "classnames";
import {Skeleton} from "@mui/material";
import apple_black from '../common/apple_black.svg'
import green_apple from '../common/green_apple.svg'
import {useSelector} from "react-redux";

const Card = ({NFTMeta, handleBoostNFT}) => {

  const applesBalance = useSelector((state) => state.global.applesBalance)
  const [isLoaded, setIsLoaded] = useState(false);

  const formatTimeStamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = +date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return `${day}th ${month} ${hours}:${minutes}`
  }

  return (
    <div className={styles.container}>
      <img
        onLoad={() => setIsLoaded(true)}
        src={NFTMeta.image}
        alt="NFT Content"
        className={classNames(isLoaded ? styles.img : styles.img_hidden)}
      />
      {!isLoaded ?
        <Skeleton sx={{bgcolor: '#0D0D0D', borderRadius: 10}} variant="rectangular" className={styles.skeleton}
                  width={235} height={235}/>
        : null
      }
      <span className={styles.text}>{NFTMeta.name}</span>
      {NFTMeta.boosted ?
        (
          <button onClick={() => handleBoostNFT(NFTMeta.id)} disabled={NFTMeta.boosted > applesBalance} className={styles.boost}>
            {NFTMeta.boosted > applesBalance ? (
              <img src={green_apple} alt="Apple icon" className={styles.apple}/>
            ) : (
              <img src={apple_black} alt="Apple icon" className={styles.apple} width={15} height={15} />
            )}
            upgrade
          </button>
        ) : (
          <button className={styles.boosted} disabled={true}>
            <img src={green_apple} alt="Apple icon" className={styles.apple}/>
            upgrade (max)
          </button>
        )}
    </div>
  );
};

export default Card;
