export const SET_HAVE_METAMASK = 'SET_HAVE_METAMASK'
export const SET_ACCOUNT_BALANCE = 'SET_ACCOUNT_BALANCE'
export const SET_ACCOUNT_ADDRESS = 'SET_ACCOUNT_ADDRESS'
export const SET_IS_CONNECTED = 'SET_IS_CONNECTED'
export const SET_IS_VALID_NETWORK_VERSION = 'SET_IS_VALID_NETWORK_VERSION'
export const SET_APPLES_BALANCE = 'SET_APPLES_BALANCE'
export const SET_MINT_PRICE = 'SET_MINT_PRICE'
export const SET_APPLES_TO_CLAIM = 'SET_APPLES_TO_CLAIM'
export const SET_TOKEN_INFO = 'SET_TOKEN_INFO'

export const setHaveMetamask = (payload) => ({type: SET_HAVE_METAMASK, payload})
export const setMintPrice = (payload) => ({type: SET_MINT_PRICE, payload})
export const setAccountBalance = (payload) => ({type: SET_ACCOUNT_BALANCE, payload})
export const setApplesBalance = (payload) => ({type: SET_APPLES_BALANCE, payload})
export const setAccountAddress = (payload) => ({type: SET_ACCOUNT_ADDRESS, payload})
export const setIsConnected = (payload) => ({type: SET_IS_CONNECTED, payload})
export const setIsValidNetworkVersion = (payload) => ({type: SET_IS_VALID_NETWORK_VERSION, payload})
export const setApplesToClaim = (payload) => ({type: SET_APPLES_TO_CLAIM, payload})
export const setTokenInfo =  (payload) => ({type: SET_TOKEN_INFO, payload})