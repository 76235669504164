import React, {useEffect, useState} from 'react';
import styles from './styles/tokens.module.css'
import {NavLink, useNavigate} from "react-router-dom";
import logo from "../common/logo.png";
import arrow_right from "../common/arrow_right.svg";
import {toast} from "react-toastify";

const Tokens = () => {

  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState('')
  const [userPayload, setUserPayload] = useState(null)
  const [tx, setTx] = useState(null)


  useEffect(() => {
    fetch('/faucet/info').then(res => res.json()).then(data => {
      setUserPayload(data)
    }).catch(() => {
      toast.error('Something went wrong. Please try again later', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setUserPayload(null)
    })
  }, []);


  const handleSendTokens = async () => {
    setTx(null)
    fetch(`/faucet/send?address=${inputValue}`)
      .then(res => {
        console.log(res)
        if (res.status !== 200) {
          return res.json().then(response => {
            throw new Error(response?.error ?? '')
          })
        }
        return res.json()
      })
      .then((data) => {
        console.log(data)
        setTx({
          type: 'info',
          value: data
        })
      })
      .catch((err) => {
          setTx({
            type: 'error',
            value: err.message ?? ''
          })
        }
      )
  }

  const isSendFormDisabled = (userPayload?.next_send_ts >0 ) && (userPayload?.next_send_ts * 1000 > Date.now())

  const handleTimeRender = (timestamp) => {
    if (userPayload?.next_send_ts) {
      let datesDifference = new Date(timestamp - Date.now() / 1000)
      const days = Math.floor(datesDifference / 86400)
      datesDifference -= days * 86400
      const hours = Math.floor(datesDifference / 3600) % 24
      datesDifference -= hours * 3600;
      const minutes = Math.floor(datesDifference / 60) % 60 > 9 ? Math.floor(datesDifference / 60) % 60 : `0${Math.floor(datesDifference / 60) % 60}`
      if (hours > 0) {
        return `${days}d. ${hours}hours`
      }
      return `${hours}hr. ${minutes}mins.`
    }
    return 'SEND!'
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <NavLink to='/' className={styles.logo}>
          <img src={logo} alt="Logo" width={40} height={40}/>
          <span className={styles.title}>the worms.<span className={styles.title_semi}>xyz</span></span>
        </NavLink>
        <button className={styles.button_top} onClick={() => navigate(-1)}>
          <div className={styles.img_wrapper}>
            <img src={arrow_right} alt="to top" width={8} height={12} className={styles.arrow_back}/>
          </div>
          <span className={styles.button_top_text}>Back</span>
        </button>
      </div>
      <div className={styles.wrapper}>
        <h1 className={styles.bigTitle}>Get <span className={styles.green}>tokens!</span></h1>
        <div className={styles.formWrapper}>
          <input
            type="text"
            className={styles.formInput}
            value={inputValue}
            onChange={(event => setInputValue(event.target.value))}
            placeholder='Write address...'
            disabled={isSendFormDisabled}
          />
          <button
            disabled={isSendFormDisabled}
            onClick={handleSendTokens}
            className={styles.formButton}
          >
            {isSendFormDisabled ? handleTimeRender(userPayload?.next_send_ts) : 'SEND!'}
          </button>
        </div>
        {tx ?
          (
            <>
              {tx.type === 'error' ? (
                <>
                  {
                    typeof tx.value !== 'string' ?
                      Object.keys(tx.value).map(key =>
                        <span className={styles.tx_error}>Something went wrong: {tx.value[key]}</span>
                      ) :
                      <span className={styles.tx_error}>Something went wrong: {tx.value}</span>
                  }
                </>
              ) : (
                <>
                  <div className={styles.tx}>
                    <span className={styles.tx_info}>Tokens successfully sent.</span>
                    <a
                      target={"_blank"}
                      rel={'noreferrer noopener'}
                      className={styles.tx_link}
                      href={`https://polygonscan.com/tx/${tx.value.tx}`}
                    >
                      Link to the transaction
                    </a>
                  </div>
                </>
              )}
            </>
          )
          : null}
        <div className={styles.rowsWrapper}>
          <span className={styles.rowsHeader}>Transactions</span>
          {
            userPayload?.tx_list?.map((row) => (
              <a
                target={'_blank'}
                rel={'noreferrer noopener'}
                href={`https://polygonscan.com/tx/${row}`}
                className={styles.row}>
                {row}
              </a>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Tokens;
