import React from 'react';
import styles from "./styles/tablet.module.css";
import logo from "../common/logo.png";
import pc from "../common/pc_image.svg";
import tablet from "../common/tablet.svg";

const Tablet = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logo_wrapper}>
          <img src={logo} alt="Logo" width={120} height={120}/>
        </div>
        <h1 className={styles.title}>We're sorry but
          <br/>
          <span className={styles.green}>The&nbsp;WORMS</span>
          <br/>
          is not available on tablet devices
        </h1>
        <div className={styles.pc_wrapper}>
          <img src={pc} alt="background" className={styles.pc_image} width={430}/>
        </div>
        <div className={styles.mobile_wrapper}>
          <img src={tablet} alt="Mobile image" className={styles.mobile_image}/>
        </div>
      </div>
    </div>
  );
};

export default Tablet;
