import React from 'react';
import styles from './styles/mobile.module.css'
import logo from '../common/logo.png'
import pc from '../common/pc_image.svg'
import mobile from '../common/mobile.svg'

const Mobile = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img src={logo} alt="Logo" width={80} height={80} />
        <h1 className={styles.title}>We're sorry but <span className={styles.green}>The&nbsp;WORMS</span><br/>is not available on mobile devices</h1>
        <div className={styles.pc_wrapper}>
          <img src={pc} alt="background" className={styles.pc_image}/>
        </div>
        <div className={styles.mobile_wrapper}>
          <img src={mobile} alt="Mobile image" className={styles.mobile_image}/>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
