import {SET_MY_NFTS_LOADING, SET_MY_NFTS_DATA} from "../actions/myNFTActions";

const initialState = {
  NFTsData: [],
  isLoading: false,
}

const myNFTReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_NFTS_DATA:
      return {
        ...state,
        NFTsData: action.payload,
      }
    case SET_MY_NFTS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      return state
  }
}

export default myNFTReducer

