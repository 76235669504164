import {SET_ALL_NFTS_DATA, SET_ALL_NFTS_LOADING} from "../actions/allNFTsActions";

const initialState = {
  NFTsData: [],
  isLoading: false,
}

const allNFTReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_NFTS_DATA:
      return {
        ...state,
        NFTsData: action.payload,
      }
    case SET_ALL_NFTS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      return state
  }
}

export default allNFTReducer

