import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import styles from './styles/myNFT.module.css';
import gold_apple from '../common/gold_apple.svg'
import geometry from '../common/geometry_nfts.svg'
import cloud_mid from '../common/cloud_mid.svg'
import cloud_big from '../common/cloud_big.svg'
import Card from "../components/Card";
import {Pagination, Tooltip} from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";

const MyNFT = ({handleBoostNFT, handleGetPersonalNFTs, checkForNextClaim, wormAddress, handleMint}) => {

  useEffect(() => {
    setIsLoading(true)
    handleGetPersonalNFTs(setIsLoading)
  }, [])


  const NFTsData = useSelector(state => state.my.NFTsData)
  const accountBalance = useSelector((state) => state.global.accountBalance)
  const applesBalance = useSelector((state) => state.global.applesBalance)
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false)
  const elementsOnPage = 12;
  const lastRow = page * elementsOnPage
  const firstRow = lastRow - elementsOnPage
  const paginatedNFTsData = NFTsData.slice(firstRow, lastRow)

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <span className={styles.balance}>{accountBalance ? +(Number(accountBalance).toFixed(5)) : 0} Matic</span>
        <span className={styles.applesBalance}>
          <img src={gold_apple} alt="Gold apple icon" width={13} height={13}/>&nbsp;
          <span className={styles.gold_text}>{applesBalance}&nbsp;</span>Apple counter
        </span>
        <Tooltip
          title={'Apples are used to upgrade your worms. Each worm generates 1 apple per 24 hours. For the first level of upgrade you need 14. For the second one you need 28. Each upgrade improves your worms visuals by adding new traits.'}>
          <span className={styles.tooltip}>?</span>
        </Tooltip>
        <img src={geometry} alt="background" className={styles.geometry}/>
        <img src={cloud_mid} alt="background" className={styles.cloud_mid}/>
      </div>
      <Scrollbars
        renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
        renderTrackVertical={props => <div {...props} className={styles.render_track}/>}
        hideTracksWhenNotNeeded
      >
        {!isLoading ? (
          <>
            {paginatedNFTsData.length ? (
              <div className={styles.nftsWrapper}>
                {paginatedNFTsData.map(meta =>
                  <Card
                    wormAddress={wormAddress}
                    key={meta.id}
                    NFTMeta={meta}
                    isMyNft={true}
                    checkForNextClaim={checkForNextClaim}
                    handleBoostNFT={handleBoostNFT}
                  />
                )}
              </div>
            ) : (
              <div className={styles.emptyWrapper}>
                <h3 className={styles.emptyTitle}>You haven't any NFT's. Mint it now</h3>
                <button onClick={handleMint} className={styles.mint}>Mint</button>
              </div>
            )
            }
          </>
        ) : (
          <div className={styles.loaderWrapper}>
            <div className={styles.loader}>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
              <div/>
            </div>
          </div>
        )
        }
      </Scrollbars>
      <img src={cloud_big} alt="background" className={styles.cloud_big}/>
      {NFTsData.length > 12 && (
        <div className={styles.footer}>
          <Pagination count={Math.ceil(NFTsData.length / 12)} page={page} onChange={handlePageChange}/>
        </div>
      )
      }
    </div>
  );
};

export default MyNFT;
