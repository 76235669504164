import styles from './styles/App.module.css'
import Header from "./components/Header";
import Main from "./components/Main";
import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setAccountAddress,
  setAccountBalance, setApplesBalance, setApplesToClaim,
  setHaveMetamask,
  setIsConnected, setMintPrice
} from "./store/actions";
import {ethers} from "ethers";
import Apples from './contracts/Apples.json'
import CryptoWorms from './contracts/CryptoWorms.json'
import {toast, ToastContainer} from "react-toastify";
import {Route, Routes} from "react-router-dom";
import MyNFT from "./pages/MyNFT";
import {setMyNFTsData} from "./store/actions/myNFTActions";
import Guide from "./pages/Guide";
import Scrollbars from "react-custom-scrollbars-2";
import {isMobile} from "react-device-detect";
import Tablet from "./pages/Tablet";
import Mobile from "./pages/Mobile";
import Tokens from "./pages/Tokens";

function App() {

  const {ethereum} = window
  const ApplesAddress = '0x5FA32eE3d7D4659C7E68cb1eede477d20343989f'
  const wormAddress = '0x12D6b190F822435E01Ff4bdB0bFfC07Ab1C64aE6';


  const dispatch = useDispatch()
  const isConnected = useSelector(state => state.global.isConnected)
  const accountAddress = useSelector(state => state.global.accountAddress)
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  const validNetworkVersion = '137'


  const connectWallet = async () => {
    try {
      if (!ethereum) {
        dispatch(setHaveMetamask(false))
        throw Error('Metamask is not installed')
      }
      if (ethereum.networkVersion !== validNetworkVersion) {
        throw Error("Wrong chain. Please check the guide!");
      }
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const wormContract = new ethers.Contract(
        wormAddress,
        CryptoWorms.abi,
        signer
      )
      const applesContract = new ethers.Contract(
        ApplesAddress,
        Apples.abi,
        signer
      )
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      const account = accounts[0];
      const balance = await provider.getBalance(account);
      const bal = ethers.utils.formatEther(balance);
      const appleBalance = await applesContract.balanceOf(account);
      const mintPrice = await wormContract.MINT_PRICE();
      const timestamp = (Math.floor(Date.now() / 1000))
      const applesToClaim = await applesContract.checkForOwner(account, timestamp)
      dispatch(setApplesToClaim(parseInt(applesToClaim._hex, 16)))
      dispatch(setMintPrice(ethers.utils.formatEther(mintPrice)))
      dispatch(setApplesBalance(parseInt(appleBalance._hex, 16)))
      dispatch(setAccountBalance(bal));
      dispatch(setAccountAddress(account));
      dispatch(setIsConnected(true));
    } catch (error) {
      dispatch(setIsConnected(false));
      toast.error(error.message ?? "Something went wrong. Please try again later.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleMint = async () => {
    try {
      if (!ethereum) {
        throw Error('Metamask is not installed')
      }
      if (!isConnected) {
        throw Error("You aren't connected to the chain")
      }
      if (ethereum.networkVersion !== validNetworkVersion) {
        throw Error("Wrong chain. Please check the guide!");
      }
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const wormContract = new ethers.Contract(
        wormAddress,
        CryptoWorms.abi,
        signer
      )
      const mintPrice = await wormContract.MINT_PRICE()
      const response = await wormContract.mint(1, {
        gasLimit: 500000,
        value: mintPrice,
      })
      toast.success(response.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.error(err)
      toast.error(err.message ?? "Something went wrong. Please try again later.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleClaimApples = async () => {
    try {
      if (!ethereum) {
        throw Error('Metamask is not installed')
      }
      if (!isConnected) {
        throw Error("You aren't connected to the chain")
      }
      if (ethereum.networkVersion !== validNetworkVersion) {
        throw Error("Wrong chain. Please check the guide!");
      }
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const applesContract = new ethers.Contract(
        ApplesAddress,
        Apples.abi,
        signer
      )
      await applesContract.claim({
        gasLimit: 1000000,
      })
      toast.success('Successfully claimed', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(err.message ?? "Something went wrong. Please try again later.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleGetPersonalNFTs = async (setIsLoading) => {
    try {
      if (!ethereum) {
        throw Error('Metamask is not installed')
      }
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const wormContract = new ethers.Contract(
        wormAddress,
        CryptoWorms.abi,
        signer
      )
      const applesContract = new ethers.Contract(
        ApplesAddress,
        Apples.abi,
        signer
      )
      const NFTData = []
      const balance = await wormContract.balanceOf(accountAddress);
      for (let index = 0; index < parseInt(balance._hex); index++) {
        const tokenId = await wormContract.tokenOfOwnerByIndex(accountAddress, index)
        const formattedTokenId = parseInt(tokenId._hex)
        const tokenMeta = await wormContract.tokenURI(formattedTokenId)
        const image = await fetch(tokenMeta).then(res => res.json())
        const isBoosted = await wormContract.boostPrice(formattedTokenId)
        console.log(parseInt(isBoosted._hex, 16))
        const timestamp = (Math.floor(Date.now() / 1000))
        const timeToClaim = await applesContract.nextClaim(tokenId, timestamp)
        const meta = {
          id: formattedTokenId,
          image: image.image,
          name: image.name,
          boosted: parseInt(isBoosted._hex, 16),
          timeToClaim: parseInt(timeToClaim._hex, 16)
        }
        NFTData.push(meta)
      }
      setIsLoading(false)
      dispatch(setMyNFTsData(NFTData))
    } catch (err) {
      toast.error(err.message ?? 'Something went wrong. Please try again later.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false)
    }
  }

  const handleBoostNFT = async (tokenId) => {
    try {
      if (!ethereum) {
        throw Error('Metamask is not installed')
      }
      if (!isConnected) {
        throw Error("You aren't connected to the chain")
      }
      if (ethereum.networkVersion !== validNetworkVersion) {
        throw Error("Wrong chain. Please check the guide!");
      }
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const wormContract = new ethers.Contract(
        wormAddress,
        CryptoWorms.abi,
        signer
      )
      const res = await wormContract.boost(tokenId, {
        gasLimit: 1000000,
      })
      toast.success('Successfully boosted.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(err.message ?? 'Something went wrong. Please try again later.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(accountAddress)
    toast.dark('Address copied to clipboard', {
      position: "bottom-center",
      autoClose: 200,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const scrollBarsRef = useRef(null)

  return (
    <>
      {isMobile ? (
        <>
          {isTablet ? <Tablet/> : <Mobile/>}
        </>
      ) : (
        <Scrollbars
          ref={scrollBarsRef}
          renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
          renderTrackVertical={props => <div {...props} className={styles.render_track}/>}
          hideTracksWhenNotNeeded
          className={styles.scrollBars}
        >
          <Routes>
            <Route path={'/'} element={(
              <div className={isConnected ? styles.connectedContainer : styles.container}>
                <Header
                  connectWallet={connectWallet}
                  handleClaimApples={handleClaimApples}
                  handleMint={handleMint}
                  handleCopyToClipboard={handleCopyToClipboard}
                />
                {!isConnected
                  ? <Main applesAddress={ApplesAddress} wormAddress={wormAddress}/>
                  : <MyNFT
                    wormAddress={wormAddress}
                    handleBoostNFT={handleBoostNFT}
                    handleGetPersonalNFTs={handleGetPersonalNFTs}
                    handleMint={handleMint}
                  />
                }
              </div>
            )}/>
            <Route path={'/guide'} element={<Guide scrollToTop={scrollBarsRef.current?.scrollToTop}/>}/>
            <Route path={'/faucet'} element={<Tokens/>}/>
          </Routes>
          <ToastContainer
            toastStyle={{backgroundColor: "#272727", color: '#fff'}}
            className={styles.toast}
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Scrollbars>
      )
      }
    </>
  );
}

export default App;

// {/*<div className={styles.wrapper}>*/}
// {/*  <Sidebar handleClaimApples={handleClaimApples} handleMint={handleMint}/>*/}
// {/*  <Routes>*/}
// {/*    <Route path='/' element={<AllNFT wormAddress={wormAddress}/>}/>*/}
// {/*    <Route path='/my_collection' element={isConnected ?*/}
// {/*      <MyNFT wormAddress={wormAddress} handleBoostNFT={handleBoostNFT}*/}
// {/*             handleGetPersonalNFTs={handleGetPersonalNFTs}/> : <AllNFT wormAddress={wormAddress}/>}/>*/}
// {/*    <Route path='*' element={<AllNFT wormAddress={wormAddress}/>}/>*/}
// {/*    <Route path='/token/:id' element={<Token getTokenInfo={getTokenInfo} wormAddress={wormAddress}/>}/>*/}
// {/*  </Routes>*/}




