import React from 'react';
import styles from './styles/main.module.css'
import geometry from '../common/geometry_title.svg'
import cloud_big from '../common/cloud_big.svg'
import cloud_mid from '../common/cloud_mid.svg'
import cloud_small from '../common/cloud_small.svg'
import worm_1 from '../common/worm_1.svg'
import worm_2 from '../common/worm_2.svg'
import worm_3 from '../common/worm_3.svg'
import worm_4 from '../common/worm_4.svg'
import planet_background from '../common/planet_background.svg'
import purple_cloud from '../common/purple_cloud.svg'
import red_cloud from '../common/red_cloud.svg'
import blue_cloud from '../common/blue_cloud.svg'
import yellow_cloud from '../common/yellow_cloud.svg'
import classNames from "classnames";

const Main = () => {

  return (
    <>
      <main className={styles.main}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>
            <span className={styles.green_text}>Take the pill </span>
            and join <br/> badass world of the
            <span className={styles.green_text}> Worms
              <span className={styles.exclamation}>!</span>
            </span>
            <img src={geometry} alt="background" className={styles.geometry}/>
          </h1>
        </div>
        <p className={styles.info}>
          The worms world starts with a collection of 2000 avatars that will give you access <br/> to the community
          ready
          for unique experience.
        </p>
        <img src={cloud_big} alt="background" className={styles.cloud_big}/>
        <img src={cloud_mid} alt="background" className={styles.cloud_mid}/>
        <img src={cloud_small} alt="background" className={styles.cloud_small}/>
      </main>
      <div className={styles.planet}>
        <img src={planet_background} alt="Planet background" className={styles.planet_background}/>
        <div className={styles.first_worm}>
          <img src={worm_1} alt="Worm background" className={styles.first_worm_image}/>
          <div className={styles.purple_cloud}>
            <img src={purple_cloud} alt="Text background" className={styles.purple_cloud_image}/>
            <span className={classNames(styles.worm_text_white, styles.purple_cloud_text)}>
              Insane <br/> Dimention!
            </span>
          </div>
        </div>
        <div className={styles.second_worm}>
          <img src={worm_2} alt="Worm background" className={styles.second_worm_image}/>
          <div className={styles.red_cloud}>
            <img src={red_cloud} alt="Text background" className={styles.red_cloud_image}/>
            <span className={classNames(styles.worm_text, styles.red_cloud_text)}>
              Get me!
            </span>
          </div>
        </div>
        <div className={styles.third_worm}>
          <img src={worm_3} alt="Worm background" className={styles.third_worm_image}/>
          <div className={styles.blue_cloud}>
            <img src={blue_cloud} alt="Text background" className={styles.blue_cloud_image}/>
            <span className={classNames(styles.worm_text_white, styles.blue_cloud_text)}>
              Wreak <br/> havoc!
            </span>
          </div>
        </div>
        <div className={styles.fourth_worm}>
          <img src={worm_4} alt="Worm background" className={styles.fourth_worm_image}/>
          <div className={styles.yellow_cloud}>
            <img src={yellow_cloud} alt="Text background" className={styles.yellow_cloud_image}/>
            <span className={classNames(styles.worm_text, styles.yellow_cloud_text)}>
              I'm badass!
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
