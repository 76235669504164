import {
  SET_ACCOUNT_ADDRESS,
  SET_ACCOUNT_BALANCE, SET_APPLES_BALANCE, SET_APPLES_TO_CLAIM,
  SET_HAVE_METAMASK,
  SET_IS_CONNECTED,
  SET_IS_VALID_NETWORK_VERSION, SET_MINT_PRICE, SET_TOKEN_INFO
} from "../actions";

const initialState = {
  haveMetamask: false,
  accountBalance: 0,
  applesBalance: 0,
  accountAddress: '',
  isConnected: false,
  isValidNetworkVersion: false,
  mintPrice: null,
  applesToClaim: null,
  tokenInfo: null,
}


const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HAVE_METAMASK:
      return {
        ...state,
        haveMetamask: action.payload
      }
    case SET_ACCOUNT_BALANCE:
      return {
        ...state,
        accountBalance: action.payload
      }
    case SET_APPLES_BALANCE:
      return {
        ...state,
        applesBalance: action.payload
      }
    case SET_ACCOUNT_ADDRESS:
      return {
        ...state,
        accountAddress: action.payload
      }
    case SET_IS_CONNECTED:
      return {
        ...state,
        isConnected: action.payload
      }
    case SET_IS_VALID_NETWORK_VERSION:
      return {
        ...state,
        isValidNetworkVersion: action.payload,
      }
    case SET_MINT_PRICE:
      return {
        ...state,
        mintPrice: action.payload,
      }
    case SET_APPLES_TO_CLAIM:
      return {
        ...state,
        applesToClaim: action.payload,
      }
    case SET_TOKEN_INFO:
      return {
        ...state,
        tokenInfo: action.payload,
      }
    default:
      return state
  }
}

export default globalReducer