import React  from 'react';
import {NavLink} from "react-router-dom";
import styles from './styles/header.module.css'
import {useSelector} from "react-redux";
import logo from '../common/logo.png'
import avatar from '../common/avatar.svg'
import apple from '../common/green_apple.svg'
import copy from '../common/copy_icon.svg'


const Header = ({connectWallet, handleClaimApples, handleMint, handleCopyToClipboard}) => {

  const isConnected = useSelector(state => state.global.isConnected)
  const accountAddress = useSelector(state => state.global.accountAddress);
  const applesToClaim = useSelector((state) => state.global.applesToClaim)

  return (
    <>
      <header className={styles.header}>
        <NavLink to='/' className={styles.logo}>
          <img src={logo} alt="Logo" width={40} height={40} className={styles.logoImage}/>
          <span className={styles.title}>the worms.<span className={styles.title_semi}>xyz</span></span>
        </NavLink>
        <div className={styles.nav}>
          <button onClick={handleMint} className={styles.header_actions} disabled={!isConnected}>Mint</button>
          <button onClick={handleClaimApples} className={styles.claim} disabled={!isConnected}>Claim
            apples{isConnected ?
              (
                <div className={styles.apples_count}>
                  <img src={apple} alt="Apple icon" width={14} height={14}/>
                  <span className={styles.apples_text}>{applesToClaim ?? 0}</span>
                </div>)
              : null}
          </button>
          <a href='https://opensea.io/collection/the-worms-xyz' rel="noreferrer noopener" target='_blank'
             className={styles.header_actions}>Collection</a>
          <NavLink to='/guide' className={styles.header_actions}>
            <span className={styles.action_text}>
              Guide
            </span>
          </NavLink>
          <a href='/faucet' className={styles.header_actions}>
            <span className={styles.action_text}>
              Get Tokens
            </span>
          </a>
        </div>
        {!isConnected ? (
          <button className={styles.connect_btn} onClick={connectWallet}>Connect Walllet</button>
        ) : (
          <div className={styles.account}>
            <img src={avatar} alt="Avatar" width={40} height={40} className={styles.avatar}/>
            <span className={styles.account_address}>
              {accountAddress.slice(0, 9)}...{accountAddress.slice(38, 42)}
            </span>
            <button onClick={handleCopyToClipboard} className={styles.copy}>
              <img src={copy} alt="Copy account address icon" className={styles.copyIcon}/>
            </button>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
